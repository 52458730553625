@font-face {
    font-family: 'OpenSans-Bold';
    font-style: normal;
    src: local('OpenSans-Bold'),
    url("../font/OpenSans-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Semibold';
    font-style: normal;
    src: local('OpenSans-Semibold'),
    url("../font/OpenSans-Semibold.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Regular';
    font-style: normal;
    src: local('OpenSans-Regular'),
    url("../font/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSans-Light';
    font-style: normal;
    src: local('OpenSans-Light'),
    url("../font/OpenSans-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'arial';
    font-style: normal;
    src: local('arial'),
    url("../font/arial.ttf") format('truetype');
}

@font-face {
    font-family: 'arialbd';
    font-style: normal;
    src: local('arialbd'),
    url("../font/arialbd.ttf") format('truetype');
}

.pageBody{
    padding-bottom: 50px;
}

.noInternetModel .modal-content {
    background: transparent !important;
    border: none;
    color: white;
    text-align: center;
    margin-top: 200px;
}

.internet h2 {
    font-family: "OpenSans-Bold";
    margin-top: 20px;
    font-size: 20px;
}

.internet img {
    width: 100px;
}

.internet i {
    font-size: 50px;
}

/*sign-in page*/
/*:root {*/
/*--themeColor: #1A4F87;*/
/*}*/
.signInBody {
    background-image: url("../img/loginBackground.jpg");
    background-size: cover;
    background-position: top;

}

.signInForm {
    max-width: 400px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.signInLogo {
    display: block;
    margin: auto;
    width: 100px;
    margin-bottom: 30px;
}

.signInForm h2 {
    color: #7AA1F0;
    font-family: 'OpenSans-Semibold';
}

.signInForm p {
    font-family: 'OpenSans-Regular';
    color: white;
}

.signInForm input {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    border: none;
    border-radius: 8px;
    height: 45px;

}

.signInForm button {
    background: #FFC60A;
    border: none;
    width: 100%;
    border-radius: 8px;
    height: 45px;
    color: white;
    font-family: 'OpenSans-Bold';
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

}

.loginLink {
    font-family: 'OpenSans-Regular';
    color: #ffffff;
    margin: 20px auto;
    text-align: center;
}

.loginLink a , .loginLink a:hover{
    color: #ffffff;
}

a.forgetLink {
    float: right;
    margin-bottom: 20px;
    position: relative;
    bottom: 10px;
    font-size: 12px;
    color: white;
    text-decoration: underline;
    font-family: 'OpenSans-Regular';

}

a.forgetLink:hover {
    color: white;
}

.showButt img {
    width: 20px;
}

.showButt {
    position: absolute;
    right: 10px;
    left: initial;
    top: 5px;
    margin: auto;
    cursor: pointer;
}

.invalid-feedback {
    font-family: 'OpenSans-Semibold';

}

.parent {
    display: table;
    width: 100%;
    height: 100%
}

.child {
    display: table-cell;
    vertical-align: middle;
}

/*Header*/
.reactSec {
    text-align: center;
}

.reactSec button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    display: block;
    font-family: 'OpenSans-Semibold';
    margin-top: 10px;
}

.reactSec h3 {
    color: #7AA1F0;
    text-align: center;
    font-size: 22px;
    font-family: 'OpenSans-Semibold';

}

.activeReact {
    border: 1px solid #1A4F87;
    padding: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    border-radius: 5px;
}

.reactSec img {
    width: 60px;
    margin: 20px 10px;
    cursor: pointer;
}

.reactSec textarea {
    min-height: 120px;
    background: #F0F0F0;
    border-radius: 10px;
}

.reactSec label {
    color: #1a4f87;
    font-family: 'OpenSans-Semibold';

}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-menu.show {
    right: 0 !important;
    left: inherit !important;
}

.dropdown .btn-secondary {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.dropdownRow img {
    width: 15px;
    display: inline-block;
}

.dropdownRow p {
    font-size: 12px;
    display: inline-block;
    margin: auto 8px;
    vertical-align: bottom;
    font-family: 'OpenSans-Semibold';

}

button.dropdown-item {
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
}

.dropdownRow span {
    font-family: 'OpenSans-Regular';
    font-size: 10px;
    float: right;
    top: 5px;
    position: relative;
}

.birthdayIcon {
    position: relative;
}

.birthdayIcon i {
    color: var(--themeColor);
    font-size: 3rem;
    text-shadow: 4px -2px 3px rgba(0, 0, 0, .16);
    position: absolute;
    right: 40px;
    bottom: -60px;
    cursor: pointer;

}

.birthdayImg {
    position: relative;
    max-width: 120px;
    margin: 20px auto;
}

.birthdayCard p {
    font-family: 'OpenSans-Semibold';
    color: #514F4E;
}

.birthdayCard button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    display: block;
    font-family: 'OpenSans-Semibold';
}

.birthdayCard {
    text-align: center;
}

.balones {
    position: absolute;
    width: 60px;
    right: -6px;
    bottom: 15px;
    z-index: 0;

}

.birthdaySec textarea {
    min-height: 120px;
}

.birthdayTemp p {
    color: var(--themeColor);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 20%;
    margin: auto;
    font-family: 'OpenSans-Semibold';
    text-align: center;
    font-size: 20px;
    max-width: 300px;

}

.birthdayTemp {
    background-image: url("../img/birthdayTemplate.svg");
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 455px;
    position: relative;
}

.modal-dialog.birthdaymodalBody {
    max-width: 550px !important;
}

.birthdaymodalBody .modal-content {
    background: transparent !important;
    border: none !important;
}

.birthdaySec button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    display: block;
    font-family: 'OpenSans-Semibold';
}

.birthdaySec input {
    min-height: 100px;
    margin: 20px auto;
    background: #F0F0F0;
}

.panelHead {
    background-image: url("../img/panelHead.png");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    background-color: var(--themeColor);

    position: relative;

}

.sideBar {
    height: 100%; /* 100% Full-height */
    min-height: 700px;

    background-color: #F2F6FE;
    padding: 25px;
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

.noPadding {
    padding: 0 !important;
}

/*.sideBar {*/
/*width: 25%;*/
/*left: 0;*/
/*background: #F2F6FE;*/
/*position: absolute;*/
/*top: 0;*/
/*bottom: 0;*/
/*padding: 25px;*/
/*height: 100vh;*/
/*}*/

.logoHeader {
    width: 80px;
    height: 80px;
    margin: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.notificationIcon {
    width: 25px;
    margin: auto;
    cursor: pointer;
}

.unseen {
    background: red;
    padding: 3px;
    border-radius: 5px;
    position: relative;
    bottom: 10px;
    color: white;
    font-family: 'OpenSans-Semibold';

}

.toggleButton {
    cursor: pointer;
}

.dot {
    width: 10px;
    height: 10px;
    background: var(--themeColor);
    border-radius: 50px;
    display: inline-block;
    margin: 0px 5px;
}

.checkedNotifi {
    color: var(--themeColor);
    width: 10px;
    height: 10px;
}

.dropdownRow:hover {
    background-color: whitesmoke;
}

.dropdownRow {
    padding: 10px 5px;
    border-bottom: 1px solid whitesmoke;
    margin: 0px 10px;
    cursor: pointer;
    min-height: 50px;

}

.dropdownList {
    position: absolute;
    height: 200px;
    overflow-x: scroll;
    min-width: 300px;
    right: 0;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    z-index: 77;
    margin-top: 10px;

}

.dropdownSec {
    position: relative;
}

.notificationMessage {
    text-align: center;
    padding: 40px;
}

.notificationMessage h3 {
    color: var(--themeColor);
    font-size: 20px;
    font-family: 'OpenSans-Bold';

}

.notificationMessage p {
    color: #3E4046;
    font-family: 'OpenSans-Semibold';
    margin-bottom: 0px;
}

/*SideBar*/
.imgsquere {
    width: 100px;
    height: 100px;
    border: 3px solid var(--themeColor);
    border-radius: 50px;
    margin: auto;
    position: relative;
    background-size: cover;
    background-position: center;
}

.editImageButton {
    position: absolute;
    bottom: -5px;
    right: -5px;
    width: 45px;
    cursor: pointer;
    background: transparent;
    border: none;
}

.imgSec {
    margin-bottom: 40px;
    cursor: pointer;
}

.inputFile {
    display: none;
}

.appButton {
    background: var(--themeColor);
    color: white;
    border: none;
    border-radius: 5px;
    margin: 8px auto;
    display: block;
    font-family: 'OpenSans-Regular';

}

.editProfile label {
    color: #3E4046;
    font-family: 'OpenSans-Semibold';
    display: block;

}

.editProfile button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 120px;
    padding: 10px;
    border-radius: 10px;
    margin: 20px auto;
    font-family: 'OpenSans-Semibold';
    display: block;
}

.editProfile input {
    background: #F0F0F0;
    border-radius: 10px;
}

.profileSideImg {
    width: 80px;
    height: 80px;
    margin: 0px 10px;
    border-radius: 50px;
    display: inline-block;
    background-size: cover;
    background-position: center;
    border: 4px solid var(--themeColor);
    position: relative;
    z-index: 3;
}

.personalIntro {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: left;
}

.personalIntroInfo {
    display: inline-block;
    vertical-align: text-bottom;

}

.personalIntroInfo h3 {
    font-size: 18px;
    color: var(--themeColor);
    font-family: 'OpenSans-Bold';
    margin: 0;
}

.personalIntroInfo p {
    font-size: 12px;
    color: #969696;
    font-family: 'OpenSans-Regular';

}

.wlcIntro h4 {
    font-size: 18px;
    color: #969696;
    margin: 0;
    font-family: 'OpenSans-Bold';

}

.wlcIntro p {
    color: var(--themeColor);
    font-family: 'OpenSans-Semibold';
}

.sideLinks {
    background: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    position: relative;
    margin-top: 40px;
}

.NavLink {
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    margin: 20px 15px;
    color: #969FB2;
}

.NavLink a:hover {
    text-decoration: none;
    color: var(--themeColor);
}

.NavLink a {
    color: #969FB2;
}

.is-active {
    color: var(--themeColor) !important;
}

select.langButton {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;
    position: absolute;
    right: 20px;
    bottom: 0;
    border-radius: 10px;
    padding: 5px;
}

.chatIcon {
    text-align: right;
    margin-bottom: 10px;
}

.chatIcon i {
    font-size: 35px;
    cursor: pointer;
    color: var(--themeColor);
    float: right;
    bottom: 30px;
    position: relative;
}

/*HomePage*/
.progressBar {
    text-align: center;
    position: relative;
    bottom: 50px;
}

.progressBar .CircularProgressbar {
    width: 100px;
    background-color: var(--themeColor);
    border-radius: 50px;
    margin: 0px 5px;
    box-shadow: 0px 3px 16px rgba(0, 0, 0, .16);

}

.leavingProgress .CircularProgressbar-path {
    stroke: #7AA1F0 !important;
}

.hoursProgress .CircularProgressbar-path {
    stroke: #18D610 !important;
}

.vacationsProgress .CircularProgressbar-path {
    stroke: #FFA439 !important;
}

.CircularProgressbar-trail {
    stroke: white !important;
}

.CircularProgressbar-text {
    fill: white !important;
    font-family: 'OpenSans-Bold';

}

.singleProgressSec {
    display: inline-block;
    position: relative;
}

.singleProgressSec p {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    bottom: 20px;
    font-size: 10px;
    color: white;
    font-family: 'OpenSans-Semibold';

}

.activeDepSec {
    background: #FAFAFA;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, .16);
    padding-top: 50px;
    padding-bottom: 50px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

.activeCircle {
    width: 15px;
    height: 15px;
    border-radius: 50px;
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    background-color: var(--themeColor);
}

.depName span {
    color: #1C1C1C;
    font-family: 'OpenSans-Bold';

}

.profileCircle {
    text-align: center;
    max-width: 100px;
}

.profileCircle p {
    font-family: 'OpenSans-Regular';
    color: #514F4E;
    margin: 0;

}

.counterSec {
    box-shadow: 0px 3px 26px rgba(0, 0, 0, .16);
    padding: 20px;
    min-width: 250px;
    border-radius: 15px;
    font-size: 1.8rem;
    text-align: center;
    color: var(--themeColor);
    font-family: 'OpenSans-Bold';
}

.counterBreak{
    background-color: #7aa1f0;
    color:#ffffff;
}

.counterSec span {
    margin: 10px;
}

.copyRight {
    background: var(--themeColor);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    /*width: 100%;*/
    /*z-index: 99;*/

}

.copyRight span {
    /*width: 75%;*/
    display: block;
    /*margin-left: auto;*/
    font-family: 'OpenSans-Semibold';

}

.startButton {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    background: var(--themeColor) !important;;
    color: white;
    font-size: 16px;
    padding: 20px;
    border: none;
    font-family: 'OpenSans-Bold';
    height: 60px;
    min-width: 200px;
}

.startButton2{
    min-width: 250px;
    border-radius: 15px;
}

.pauseButton img {
    width: 100%;
}

.pauseButton {
    background: #7AA1F0;
    border: none;
    padding: 10px;
    width: 50px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 60px;
    vertical-align: bottom;
}

.platButton img {
    width: 100%;
}

.platButton {
    background: #7AA1F0;
    border: none;
    padding: 10px;
    width: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 60px;
    vertical-align: bottom;
}

.actionButtons {
    margin-top: 20px;
}

.counterBody {
    margin: 40px auto;
    text-align: center;
}

.counterBody p{
    margin: 20px auto 0px;
    font-family: 'OpenSans-Semibold';
    color: #FF2626;
}

/*ProfilePage*/
.profileTable {
    margin: 20px auto;
    width: 100%;
    max-width: 600px;
    padding: 15px;
}

.profileTable th {
    font-family: 'OpenSans-Semibold';
    color: #3E4046;
}

.salarySheetButt {
    background: var(--themeColor);
    max-width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.salarySheetButt a:hover {
    color: white;
    text-decoration: none;
}

.salarySheetButt a {
    color: white;
    font-family: 'OpenSans-Semibold';

}

.salarySheetButt img {
    margin: 5px;
    width: 20px;

}

.logOutButt {
    background: #C7C7C7;
    border-radius: 10px;
    color: white;
    padding: 10px;
    float: right;
    font-family: 'OpenSans-Semibold';
    border: none;
}

.editButton {
    background: #FF7171;
    border: none;
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    max-width: 100px;
    margin: auto;
    display: block;
    color: white;
    font-family: 'OpenSans-Bold';
    margin-bottom: 20px;

}

/*ChatPage*/
.sent_msg {
    background: #D9D9D9;
    padding: 5px 10px 5px 12px;
    border-radius: 12px 15px 15px 0;
    margin: 0;
    margin-left: auto;
    max-width: 250px;
    position: relative;
    display: inline-block;

}

.messageSide {
    position: relative;
    min-height: 450px;
}

.inbox_msg{
    min-height: 500px;
    position: relative;
}

.sent_msgParg {
    font-size: 14px;
    color: #6C6C6C;
    width: 100%;
    font-family: 'OpenSans-Semibold';
    margin: 0;
}

.received_msg {
    background: #7AA1F0;
    border-radius: 12px 15px 0px 15px;
    margin-right: auto;
    max-width: 250px;
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 30px 20px;
    min-width: 300px;
    min-height: 100px;
}

.incoming_msg{
    margin-bottom: 25px;
}

.incoming_msg:last-child , .outgoing_msg:last-child{
    margin-bottom: 0px;
}

.received_msgParg {
    font-size: 14px;
    color: white;
    width: 100%;
    font-family: 'OpenSans-Semibold';
    margin: 0;
}

.outgoing_msg {
    overflow: hidden;
    margin-bottom: 25px;
    text-align: right;
}

.sent_msg {
    text-align: left;
    width: 46%;
    padding: 30px 20px;
    min-width: 300px;
    min-height: 100px;
}

.received_head {
    font-family: 'OpenSans-Semibold';
    font-size: 12px;
}

.sent_head {
    font-family: 'OpenSans-Semibold';
    font-size: 12px;
}

.chatDate {
    font-size: 10px;
    color: white;
    position: absolute;
    right: 10px;
    top: 12px;
}

.type_msg{
    height: 50px;
    position: relative;
    max-width: 800px;
    margin: auto;
    width: 100%;
}

.input_msg_write {
    border: 1px solid #A2A2A2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    border-radius: 30px;
    padding: 0px 15px;
    background: white;
}

.input_msg_write input {
background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
border: medium none;
color: #4c4c4c;
font-size: 15px;
min-height: 48px;
width: 100%;
outline: none;
font-family: 'OpenSans-Regular';
}

.incoming_msg_img {
display: inline-block;
width: 6%;
vertical-align: top;
margin: 5px;

}

.incoming_msg_img img {
width: 100%;
border-radius: 50%;
border: 3px solid var(--themeColor);
}

.messaging {
padding: 30px;
max-height: 500px;
overflow-y: scroll;
}

.attach_send_btn {
background: white none repeat scroll 0 0;
border: none;
border-radius: 50%;
color: #fff;
cursor: pointer;
font-size: 15px;
height: 33px;
position: absolute;
right: 60px;
left: initial;
top: 8px;
width: 33px;
}

.msg_send_btn {
background: white none repeat scroll 0 0;
border: none;
border-radius: 50%;
color: #fff;
cursor: pointer;
font-size: 15px;
height: 33px;
position: absolute;
right: 15px;
top: 8px;
width: 33px;
background: var(--themeColor);
}

.msg_send_btn i{
transform: rotate(40deg);
}

.msg_history {
height: 350px;
overflow-y: auto;
}

/*salary Page*/
.salaryInfo {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    border-radius: 15px;
}

.salaryCard h3 {
    font-family: 'OpenSans-Bold';
    color: #F37040;
    font-size: 20px;
}

.salaryInfo .table {
    margin: 0;
}

.salaryInfo .table th , .salaryInfo .table td{
    border-top: 0px;
}

.salaryInfo .table tr{
    border-top: 1px solid #dee2e6;
    padding: 10px 20px;
}

.salaryInfo .table tr:first-child {
    border-top: 0px;
}

.salaryInfo .table tr:last-child {
    background: #7AA1F0;
    color: white;
    padding: 10px;
}

.salaryInfo .table tr:last-child th{
    border-bottom-left-radius: 15px;
}

.salaryInfo .table tr:last-child td{
    border-bottom-right-radius: 15px;
}


.salaryCard {
    margin: 20px;
}

.salaryInfo table th {
    font-family: 'OpenSans-Semibold';
    font-size: 14px;

}

/*my team page*/
.searchBut {
    background: #7AA1F0;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 25px;
    height: 45px;
    font-family: 'OpenSans-Semibold';
    min-width: 100px;

}

.searchInput input {
    width: 100%;
    border-radius: 10px;
    border: none;
    padding: 15px;
    height: 45px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    font-family: 'OpenSans-Regular';
}

.memberTable h2 {
    margin-bottom: 20px;
    color: var(--themeColor);
    font-size: 22px;
    font-family: 'OpenSans-Bold';

}

.memberTable {
    max-width: 800px;
    margin: 20px auto;
}

.memberTasks {
    max-width: 800px;
    margin: 30px auto;
}

.searchRow {
    background: #F2F6FE;
    padding-top: 20px;
    padding-bottom: 20px;
}

.teamRow{
    padding-bottom: 50px;
}

.messageButt img {
    width: 22px;
    margin: 2px;
    vertical-align: bottom;
}

.messageButt {
    height: 45px;
    border: none;
    background: var(--themeColor);
    color: white;
    border-radius: 20px;
    padding: 0px 15px;
    font-family: 'OpenSans-Semibold';
    margin: auto;
    display: block;
}

.searchForm i {
    color: #1a4f87;
    position: absolute;
    left: 50px;
}

.introTeamInfo {
    display: inline-block;
    vertical-align: text-bottom;
}

.introTeamInfo h3 {
    font-family: 'OpenSans-Bold';
    color: #514F4E;
    font-size: 16px;
    margin: 0;

}

.introTeamInfo p {
    font-family: 'OpenSans-Regular';
    color: #969FB2;
    font-size: 12px;

}

.teamSingleCard {
    background: #EFEFEF;
    padding: 20px;
    margin: 20px auto;
    border-radius: 20px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 0px;
}

.teamCardButt a:hover {
    color: white;
    text-decoration: none;
}

.teamCardButt a {
    color: white;
    font-family: 'OpenSans-Semibold';

}

.teamCardButt {
    border: none;
    color: white;
    padding: 10px;
    border-radius: 12px;
    width: 100%;
    margin: 8px auto;
    display: block;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    position: relative;
    font-family: 'OpenSans-Semibold';
    font-size: 15px;
}

.teamCardButtons {
    max-width: 500px;
    margin: auto;
}

.requestCircle {
    color: white;
    background: red;
    min-width: 25px;
    margin: auto;
    border-radius: 50px;
    position: absolute;
    right: -5px;
    top: -5px;
    font-family: 'OpenSans-Semibold';

}

.modalBody .modal-content {
    border-radius: 30px;
}

.msgModalSec h3 {
    color: var(--themeColor);
    font-size: 18px;
    font-family: 'OpenSans-Semibold';
    margin: 20px auto;

}

.msgModalSec textarea {
    min-height: 200px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .17);
    width: 100%;
    margin: auto;
    display: block;
}

.msgModalSec button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 100px;
    padding: 10px;
    border-radius: 10px;
    margin: 20px auto;
}

.msgModalSec {
    text-align: center;
    max-width: 350px;
    margin: auto;
}

/*check in page */
.currentProgress {
    margin: 25px auto;
}

.currentProgress h3 {
    color: #969696;
    font-family: 'OpenSans-Semibold';
    font-size: 20px;
    text-align: center;
}

.sessionButton {
    margin-left: auto;
    display: block;
    margin-top: 5px;
    background: transparent;
    border: none;
    margin-bottom: 10px;
    text-decoration: underline;
    font-family: 'OpenSans-Bold';
    font-size: 12px;
    color: var(--themeColor) !important;

}

.sessionBody {
    background-color: rgb(240, 240, 240);
    padding: 8px;
    margin-top: 10px;
}

.currentProgress .progress-bar {
    background-color: #18D610 !important;

}

.progress-bar {
    background-color: var(--themeColor) !important;

}

.checkInSec h3 {
    text-align: center;
    font-size: 20px;
    font-family: 'OpenSans-Semibold';

}

.checkInSec {
    padding: 20px 0px;
}

.rangeTime span {
    font-family: 'OpenSans-Regular';

}

.rangeTime p {
    display: inline-block;
    margin: 5px;
    font-family: 'OpenSans-Semibold';
    color: #3E4046;

}

.rangeTime {
    display: inline-block;
    margin: auto 10px;
}

.totalTime {
    font-family: 'OpenSans-Semibold';
    color: #3E4046;
    float: right;

}

/*public Vacations Page*/
.vacationsTable {
    font-family: 'OpenSans-Regular';
}

.vacationsTable table{
    text-align: center;
}

.vacationsTable th {
    font-family: 'OpenSans-Semibold';
}

/*help page*/
.commentSec h3 {
    color: #7AA1F0;
    font-family: 'OpenSans-Bold';
    font-size: 22px;

}

.commentSec textarea {
    width: 100%;
    background: #F0F0F0;
    border: none;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .16);
    min-height: 150px;
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'OpenSans-Regular';

}

.helpBody {
    max-width: 800px;
    margin: 20px auto;
}

.commentSec a , .commentSec button {
    background: var(--themeColor);
    color: white;
    border: none;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
    width: 100%;
    max-width: 200px;
    padding: 10px;
    border-radius: 10px;
    font-family: 'OpenSans-Semibold';
    margin: auto;
    display: block;
    text-align: center;
    text-decoration: none;
}

.commentSec a:hover{
    color: #fff;
    text-decoration: none;
}

.contactUsDiv {
    background: #EFF4FF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .16);
}

.contactUsDiv p {
    margin-top: 10px;
    margin-bottom: 0;
    font-family: 'OpenSans-Bold';
    color: #3E4046;
}

.complainType img {
    cursor: pointer;
}

.complainType {
    text-align: center;
    margin: 40px auto;
    max-width: 500px;
}

.complainType p {
    font-family: 'OpenSans-Bold';
    color: #3E4046;
    margin-top: 20px;
}

.commentSec {
    padding-bottom: 30px;
}

/*requests page*/
.react-tabs__tab--selected {
    background: var(--themeColor) !important;
    border-color: transparent !important;
    color: white !important;
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16) !important;

}

.react-tabs__tab {
    font-family: 'OpenSans-Semibold';
    margin: 5px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    border-radius: 10px;
}

.react-tabs__tab-list {
    border-bottom: none !important;
    margin-bottom: 30px !important;

}

.tabsBody {
    margin: 20px auto 50px;
}

.introRequestInfo h3 {
    font-family: 'OpenSans-Bold';
    color: #514F4E;
    font-size: 16px;
    margin: 0;

}

.introRequestInfo p {
    font-family: 'OpenSans-Regular';
    color: #969FB2;
    font-size: 12px;

}

.requestDetails h3 {
    font-family: 'OpenSans-Bold';
    color: #FFA439;
    font-size: 20px;
}

.requestDetails p {
    font-family: 'OpenSans-Regular';
    color: #3E4046;
    font-size: 18px;
}

.requestDetails p span {
    font-family: 'OpenSans-Bold';
    color: #969FB2;

}

.requestCard {
    position: relative;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, .16);
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
}

.requestButtons button {
    border: none;
    padding: 10px;
    color: white;
    width: 100%;
    max-width: 120px;
    margin: auto 20px;
    border-radius: 10px;
    font-family: 'OpenSans-Semibold';

}

.requestButtons {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
}

/*my requests page*/
.addRequestButton img {
    width: 30px;
    margin: auto 5px;
    vertical-align: middle;
}

.addRequestButton:hover {
    text-decoration: none;
    color: #969696;
}

.addRequestButton {
    color: #969696;
    font-size: 14px;
    padding: 5px;
    border: 2px solid #fdc60c;
    border-radius: 15px;
    background: transparent;
    font-family: 'OpenSans-Semibold';
    margin: auto;
    cursor: pointer;
    display: block;
    text-align: center;
    max-width: 180px;
    width: 100%;
}

.requestModalSec {
    padding: 15px;
}

.requestModalSec label {
    color: var(--themeColor);
    font-family: 'OpenSans-Bold';

}

.typesSelect{
    border-radius: 15px;
    color: #969696;
    font-family: 'OpenSans-Semibold';
    margin: auto;
    text-align: right;
}

.requestModalSec p{
    text-align: center;
    margin: 30px;
    font-family: 'OpenSans-Semibold';
}

.requestModalSec select {
    background: #F0F0F0;
    border-radius: 15px;
    color: #969696;
    font-family: 'OpenSans-Semibold';
    margin: auto;
}

.requestModalSec input {
    background: #F0F0F0;
    border-radius: 15px;
    font-family: 'OpenSans-Regular';
}

.requestModalSec textarea {
    background: #F0F0F0;
    border-radius: 15px;
    min-height: 120px;
    font-family: 'OpenSans-Regular';
}

.requestModalSec img {
    padding: 30px 0px;
    width: 30px;
}

.requestModalSec button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 120px;
    padding: 10px;
    border-radius: 10px;
    margin: 20px auto;
    display: block;
    font-family: 'OpenSans-Semibold';

}

input#requestDetails {
    min-height: 150px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, .16);
}

.myRequestCard h3 {
    color: var(--themeColor);
    font-size: 18px;
    font-family: 'OpenSans-Bold';
    position: relative;
}

.myRequestCard h4 {
    font-size: 16px;
    font-family: 'OpenSans-Semibold';
    text-align: center;
    border-left: 1px solid #A5A5A5;

}

.approvalBtns{
    position: absolute;
    margin: auto;
    text-align: center;
    left: 0;
    right: 0;
    bottom: -10px;
}

.approvalBtns button{
    font-family: "OpenSans-Semibold";
    color: #ffffff;
    padding: 10px;
    border: none;
    margin: 10px;
}

.approvalBtns button img{
    width: 85%;
}

.approvalBtns .accept{
    background-color: #18D610;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.approvalBtns .reject{
    background-color: #FF2626;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.approvalBtns .accepted{
    background-color: #18D610;
    border-radius: 15px;
    min-width: 100px;
}

.approvalBtns .rejected{
    background-color: #FF2626;
    border-radius: 15px;
    min-width: 100px;
}

.myRequestCard {
    box-shadow: 0px 0px 22px rgba(0, 0, 0, .16);
    border-radius: 15px;
    padding: 20px;
    margin: 10px auto 25px;
}

.myRequestCard hr {
    margin: 10px;
}

.dateInfo span {
    color: var(--themeColor);
    display: block;
    font-family: 'OpenSans-Bold';
    margin: 10px auto;

}

.dateInfo {
    color: #3E4046;
    font-size: 10px;
    text-align: center;
    font-family: 'OpenSans-Regular' !important;

}

.invalid-feedback {
    text-align: left;
    font-family: 'OpenSans-Semibold';
}

/*my tasks page*/
.taskRightSide {
    margin-bottom: 30px;
}

.editIcon {
    vertical-align: middle;
    color: var(--themeColor);
}

.editIcon:hover {
    color: var(--themeColor);
}

.toggleHead {
    text-align: left;
    width: 100%;
    display: block;
    position: relative;
}

.toggleHead p {
    color: #A3A3A3;
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    margin: 0;
}

.toggleHead h3 {
    color: var(--themeColor);
    font-size: 16px;
    font-family: 'OpenSans-Bold';

}

.toggleHead img {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
}

.btn-link {
    width: 100%;
}

.btn-link:hover {
    text-decoration: none !important;
}

.btn-link:focus {
    text-decoration: none !important;
}

.card-header {
    background: #F2F6FE !important;
}

.accordion {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 50px;
}

.accordion > .card:first-child {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.accBody p {
    color: #989898;
    font-family: 'OpenSans-Regular';

}

.changeStateSec h3 {
    font-size: 18px;
    color: #3E4046;
    font-family: 'OpenSans-Bold';

}

.backlogButton button img {
    width: 100%;
}

.backlogButton button {
    margin: auto;
    display: block;
    background: transparent;
    border: 2px solid transparent;
    padding: 5px;
}

.backlogButton button:hover {
    border-radius: 5px;
    border: 2px solid #FF2626;
}

.activeState {
    border-radius: 5px;
    border: 2px solid #F2F6FE !important;
    cursor: not-allowed !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

}

.inProgressButton button img {
    width: 100%;
}

.inProgressButton button {
    margin: auto;
    display: block;
    background: transparent;
    border: 2px solid transparent;
    padding: 5px;
}

.inProgressButton button:hover {
    border-radius: 5px;
    border: 2px solid #FFA439;
}

.DoneButton button img {
    width: 100%;
}

.DoneButton button {
    margin: auto;
    display: block;
    background: transparent;
    border: 2px solid transparent;
    padding: 5px;
}

.DoneButton button:hover {
    border-radius: 5px;
    border: 2px solid #18D610;
}

.swiper-button-next {
    background-image: url("../img/swiperArrow.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;

}

.swiper-button-prev {
    background-image: url("../img/swiperArrow.svg") !important;
    background-position: center;
    background-size: cover;
    width: 35px !important;
    height: 35px !important;
    transform: rotate(180deg);

}

.profileSwipe {
    text-align: right;
    display: inline-block;
    position: relative;
    min-height: 75px;
    width: 100%;
}

.accBody .profileSideImg{
    margin: 0px;
    position: absolute;
}

.swiper-slide{
    text-align: center;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    display: none;
}

.swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    display: none;
}

.rightSide {
    background: #F2F6FE;
    padding: 10px;

}

.react-calendar {
    background: transparent !important;
    border: none !important;
}

.react-calendar__navigation {
    background: #7AA1F0;
}

.react-calendar__tile--active {
    background: #7aa1f0 !important;
    border-radius: 50px !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: #7aa1f0 !important;
}

button.react-calendar__navigation__label {
    color: #4263A7 !important;
}

button.react-calendar__navigation__arrow {
    color: white !important;
}

.taskProgress p {
    color: #989898;
    font-family: 'OpenSans-Bold';

}

.taskProgress .progress-bar {
    background-color: var(--themeColor) !important;
}

.historyProgress p {
    color: #989898;
    font-family: 'OpenSans-Bold';

}

.historyProgress img {
    width: 30px;
    margin: 10px;
}

.historyProgress span {
    color: #3E4046;
    font-family: 'OpenSans-Bold';
    font-size: 14px;
    margin: 10px;
}

.taskProgress {
    background: white;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

/*add task Page*/
.taskForm h3 {
    font-size: 22px;
    margin-bottom: 30px;
    color: var(--themeColor);
    font-family: 'OpenSans-Bold';

}

.taskForm {
    margin: 20px auto;
    margin-bottom: 50px;
    max-width: 800px;
}

.taskForm label {
    font-family: 'OpenSans-Bold';
    color: var(--themeColor);

}

.taskForm textarea {
    background: #F0F0F0;
    min-height: 150px;
    font-family: 'OpenSans-Bold';
    font-size: 12px;

}

.taskForm select {
    background: #F0F0F0;
}

.taskForm input {
    background: #F0F0F0;
}

.toggleImg {
    display: block;
    margin: auto;
}

.taskForm .form-group {
    margin-bottom: 30px !important;
}

.taskForm button {
    background: var(--themeColor);
    color: white;
    border: none;
    width: 100%;
    max-width: 150px;
    padding: 10px;
    border-radius: 10px;
    margin: auto;
    display: block;
    font-family: 'OpenSans-Semibold';

}

.taskTeam {
    text-align: center;
}

.taskTeam p {
    color: #989898;
    font-family: 'OpenSans-Semibold';
}

.css-2b097c-container{
    z-index: 10000000;
}

.taskTeam .profileSideImg {
    border: none;
}

.taskTeam:hover .profileSideImg {
    cursor: pointer;
    opacity: .8;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

.colorChecked {
    border: 5px solid #707070;
}

.checked {
    cursor: pointer;
    text-align: center;
}

.checked p {
    color: var(--themeColor);
    font-family: 'OpenSans-Semibold';
}

.userError {
    color: #dc3545 !important;
    font-family: 'OpenSans-Semibold';
    font-size: 12px;

}

.colorSquare {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    margin: 0px 10px;
}

/*my meetings page*/
.addMeetingRow select {
    background: var(--themeColor);
    /*color: white;*/
    max-width: 200px;
}

.addMeetingRow p {
    color: #3E4046;
    font-family: 'OpenSans-Bold';
    margin-top: 20px;

}

.addMeetingRow {
    margin: 20px auto;
}

.meetingHistory .row{
    margin: 0px;
}

.meetingHistory p {
    color: #989898;
    font-family: 'OpenSans-Bold';
}

.meetingHistory h3 {
    color: #FFA439;
    font-family: 'OpenSans-Bold';
    font-size: 16px;
}

.meetingHistory h4 {
    color: #3E4046;
    font-family: 'OpenSans-Semibold';
    font-size: 16px;
}

.errorBody h3 {
    font-family: 'OpenSans-Bold';
    color: #1a4f87;
    font-size: 20px;
    text-align: center;

}

.errorBody p {
    text-align: center;
    font-family: 'OpenSans-Bold';
    color: #3E4046;

}

.errorBody img {
    width: 50px;
    margin: auto;
    display: block;
    margin-bottom: 20px;
}

.successPrg {
    color: #18D610 !important;
    font-family: 'OpenSans-Bold' !important;
    font-size: 14px;

}

.loadingMargin {
    margin: auto;
    height: 100vh;
}

button:disabled {
    opacity: .6;
    cursor: not-allowed;
}

.emptyObject {
    color: #C7C7C7;
    font-size: 18px;
    text-align: center;
    font-family: 'OpenSans-Bold';
    margin: 250px auto;
}

.meetingHistory .emptyObject{
    margin: auto;
}

@media (max-width: 991px) {

}

@media (max-width: 767px) {
    .progressBar {
        margin-top: 20px;
        bottom: 0;
        margin-bottom: 20px;
    }

    .searchForm input {
        margin: auto !important;
    }

    .searchBut {
        margin: 20px auto;
    }

    .progressSec {
        text-align: center;
    }

    .totalTime {
        float: initial;
    }

    .panelHead {
        padding-bottom: 30px;
        text-align: center;
        background-image: none;

    }

    .dropdown-menu.show {
        margin: auto;
        right: 0 !important;
        left: 0 !important;
        max-width: 300px;
    }

    .personalIntro {
        margin: 20px auto;
        display: block;
        text-align: center;
    }

    .wlcIntro {
        text-align: center;
    }

    .chatIcon i {
        margin: auto;
        display: block;
        float: initial;
        bottom: 0;
        text-align: center;

    }

    .sideLinks {
        max-width: 300px;
        margin: auto;
    }

    .salaryInfo {
        max-width: 300px;
        margin: auto;
    }

    .salaryCard h3 {
        text-align: center;
    }

    .requestModalSec img {
        margin: auto;
        display: block;
    }

    .myRequestCard {
        max-width: 300px;
        margin: 20px auto;
        text-align: center;

    }

    .react-calendar {
        margin: auto;
    }

    .addMeetingRow {
        text-align: center;
    }

    .addMeetingRow select {
        margin: 20px auto;
    }

    .commentSec a , .commentSec button{
        margin: 20px auto;
        display: block;
    }

    .commentSec h3 {
        text-align: center;
    }

    .NavLink {
        text-align: center;
    }

    select.langButton {
        margin: 20px auto;
        display: block;
        position: relative;
        left: 0;

    }

    .rangeTime {
        float: initial;
    }

    .depName {
        text-align: center;
        margin-bottom: 20px;
    }

    ul.react-tabs__tab-list {
        text-align: center;
    }

    .contactUsDiv p {
        text-align: center;
    }

    .taskForm img {
        margin: auto;
        display: block;
    }

    .myRequestCard h4 {
        border: none;
    }

    .logoHeader {
        margin: auto;
    }
}